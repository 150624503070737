import React, { useEffect } from 'react';
import _ from 'lodash';
import { injectIntl, defineMessages, FormattedMessage } from '@kyruus/intl';

import VideoComponent from '../Video';

import { LogoImage } from '../provider/provider-summary';
import SecureNewTabLink from '../utils/secure-new-tab-link';
import { pages } from '../tracking/constants';

const messages = defineMessages({
  ariavideo: {
    id: 'aria.label.provider.profile.video',
    description: "Accessibility text for a provider's video",
    defaultMessage: 'Provider video'
  },
  aboutprovider: {
    id: 'provider.profile.panel.aboutprovider',
    description: 'Title for a section giving information about a provider',
    defaultMessage: 'About {providerName}'
  }
});

function AboutFieldValue({ value }) {
  const Logo = value.logo ? (
    <div className="col-md-4 col-sm-6 hidden-xs hidden-print text-center">
      <LogoImage
        url={value.logo}
        affiliation={value.primary}
        className="affiliation-logo"
      />
    </div>
  ) : null;

  let fieldValueClass = 'pb-s about-panel-item col-xs-12';
  if (value.logo) {
    fieldValueClass += ' col-md-8 col-sm-6 has-logo-image';
  }

  let primaryValue, secondaryValues;
  switch (value.render_type) {
    case 'url':
      primaryValue = (
        <SecureNewTabLink href={value.primary}>
          {value.primary}
        </SecureNewTabLink>
      );
      break;
    case 'rich_text':
      fieldValueClass += ' fc-gray-1';
      // eslint-disable-next-line react/no-danger
      primaryValue = (
        <div dangerouslySetInnerHTML={{ __html: value.primary }} />
      );
      break;
    case 'hierarchy':
      fieldValueClass += ' fc-gray-1 is-hierarchy';
      primaryValue = value.primary;
      if (!_.isEmpty(value.secondary)) {
        secondaryValues = (
          <ul className="ml-l mt-s subspecialty-list">
            {_.map(value.secondary, (secondary) => {
              return (
                <li className="about-panel-item" key={secondary}>
                  {secondary}
                </li>
              );
            })}
          </ul>
        );
      }
      break;
    default:
      fieldValueClass += ' fc-gray-1';
      primaryValue = value.primary;
  }

  return (
    <div className="row about-panel-row" role="listitem">
      <div className={fieldValueClass}>
        {primaryValue}
        {secondaryValues}
      </div>
      {Logo}
    </div>
  );
}

function BaseVideo({ intl, video_url }) {
  if (video_url) {
    return (
      <div className="row hidden-ie8 hidden-print">
        <div className="col-xs-12">
          <VideoComponent
            url={video_url}
            accessibilityText={intl.formatMessage(messages.ariavideo)}
            iframeId={'main-provider-video'}
            containerClass={'video-wrapper'}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function ProfessionalStatement({ data }) {
  if (data.professional_statement) {
    return (
      <p
        id="about-panel-professional-statement"
        className="about-panel-item horizontal-rule-wide"
      >
        {data.professional_statement}
      </p>
    );
  } else if (data.professional_statement_rich_text) {
    return (
      // eslint-disable-next-line react/no-danger
      <div
        id="about-panel-professional-statement"
        className="mb-m"
        dangerouslySetInnerHTML={{
          __html: data.professional_statement_rich_text
        }}
      />
    );
  } else {
    return null;
  }
}

const Video = injectIntl(BaseVideo);

const AboutField = ({ field, log }) => {
  const containerId = `about-panel-${field.field}`;
  const fieldLabelMessageDescriptor = {
    id: `field.name.${field.field}`,
    defaultMessage: _.startCase(field.field),
    description: 'The title displayed above the summary field'
  };

  useEffect(() => {
    const container =
      typeof window !== undefined
        ? window.document.getElementById(containerId)
        : null;
    const anchors = container ? container.querySelectorAll('a') : null;
    const trackAnchorClick = (e) => {
      const { href } = e.target;

      log(`user_action.${pages.PROVIDER_PROFILE}.about_field_link_clicked`, {
        href,
        field: field.field
      });
    };

    if (anchors) {
      anchors.forEach((anchor) =>
        anchor.addEventListener('click', trackAnchorClick)
      );
    }

    return () => {
      if (anchors) {
        anchors.forEach((anchor) =>
          anchor.removeEventListener('click', trackAnchorClick)
        );
      }
    };
  }, [containerId, field.field, log]);

  return (
    <div
      id={containerId}
      className="row mb-s horizontal-rule-wide"
      key={field.field}
      aria-labelledby={`${containerId}-heading`}
      role="region"
    >
      <div
        className="col-sm-3 col-md-2 mb-s summary-field-title"
        role="heading"
        aria-level="3"
        id={`${containerId}-heading`}
      >
        <FormattedMessage {...fieldLabelMessageDescriptor} />
      </div>
      <div className="col-sm-9 col-md-10" role="list">
        {_.map(field.values, (value) => {
          return <AboutFieldValue value={value} key={value.primary} />;
        })}
      </div>
    </div>
  );
};

function About({ data, providerName, degrees, log }) {
  return (
    <div
      id="provider-details-about"
      className="row panel mb-m pt-m pb-s"
      role="region"
      aria-labelledby="AboutSectionHeading"
    >
      <div className="col-xs-12">
        <div className="row mb-m">
          <div className="col-xs-12">
            <h1 className="fw-6 fs-l" id="AboutSectionHeading">
              <FormattedMessage
                {...messages.aboutprovider}
                values={{
                  providerName,
                  degrees: (degrees ?? []).map((d) => d.name).join(', ')
                }}
              />
            </h1>
          </div>
        </div>
        <Video video_url={data.video_url} />
        <div className="row mt-l">
          <div className="col-xs-12">
            <ProfessionalStatement data={data} />
            {_.map(data.fields, (field) => (
              <AboutField field={field} log={log} key={field.field} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
