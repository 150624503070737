import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import _kebabCase from 'lodash/kebabCase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import querystring from 'querystring';

import Summary from './summary';
import Locations from './locations';
import Nav from './nav';
import About from './about';
import Experience from './experience';
import Reviews from './reviews';
import Insurances from './insurances';
import LoadingOverlay from '../shared/loading';
import PoweredByLogo from '../shared/powered-by-logo';
import Error from '../error';
import ProfileMetas from './profile-metas';
import { pageViewEvent } from '../tracking/tracking-utils';
import { pages } from '../tracking/constants';
import { locationsModuleEnabled } from '../utils/location';
import { FROM_SEARCH_LIST, FROM_SEARCH_MAP } from '../shared/provider-list';
import { STICKY_QUERY_PARAMS } from '../utils/constants';
import { withQueryParam } from '../utils/url';
import { isCCF } from '../utils/cerner';

// list of query params to omit when building the "back to" links
const OMIT_QUERY_PARAMS = ['from'];

const messages = defineMessages({
  return_to_results: {
    id: 'provider.profile.returntoresults',
    description: 'Link to return to search results',
    defaultMessage: 'Return to search results'
  },
  new_search: {
    id: 'provider.profile.startanewsearch',
    description: 'Link to start a new search',
    defaultMessage: 'Start a new search'
  },
  or: {
    id: 'provider.profile.navigation.or',
    description: 'Junction of multiple options',
    defaultMessage: 'or'
  }
});

function SearchLink({ linkType, to, log }) {
  const logClick = log
    ? () => log(`user_action.provider_detail.${linkType}`)
    : undefined;

  return (
    <Link id={_kebabCase(linkType)} to={to} onClick={logClick}>
      <FormattedMessage {...messages[linkType]} />
    </Link>
  );
}

function SearchLinks({ currentQuery, config }) {
  let ReturnToResultsLink, OrConnector;

  if (!_isEmpty(currentQuery)) {
    let linkDestination;
    if (currentQuery.specialty_page) {
      const nearParam = currentQuery.location
        ? `/near/${encodeURIComponent(currentQuery.location)}`
        : '';
      linkDestination = `/specialty/${encodeURIComponent(
        currentQuery.specialty
      )}${nearParam}`;
    } else {
      let pathname = '/search';
      if (currentQuery.from) {
        if (currentQuery.from === FROM_SEARCH_MAP) {
          pathname = '/locations';
        } else if (currentQuery.from === FROM_SEARCH_LIST) {
          pathname = '/search';
        }
      }
      linkDestination = {
        pathname,
        search: querystring.stringify(_omit(currentQuery, OMIT_QUERY_PARAMS))
      };
    }

    ReturnToResultsLink = (
      <SearchLink linkType="return_to_results" to={linkDestination} />
    );
    OrConnector = (
      <span>
        {' '}
        <FormattedMessage {...messages.or} />{' '}
      </span>
    );
  }

  let newSearchLink = config.search_home || '/';
  STICKY_QUERY_PARAMS.forEach((param) => {
    if (param in currentQuery) {
      newSearchLink = withQueryParam(newSearchLink, param, currentQuery[param]);
    }
  });

  return (
    <div className="col-md-12">
      <div className="utility-bar pt-s pb-s mb-s hidden-print">
        <h4 className="d-inline-block">
          {ReturnToResultsLink}
          {OrConnector}
          <SearchLink linkType="new_search" to={newSearchLink} />
        </h4>
      </div>
    </div>
  );
}

function Profile({
  profile: provider,
  currentQuery,
  config,
  loading,
  error,
  history,
  log,
  fetchSlots,
  fetchPurposesByProviderId,
  slotsByProviderId,
  slotsStatus,
  availablePurposesByProviderId,
  location,
  tokens,
  device
}) {
  useEffect(() => {
    if (provider && !error && !loading) {
      log(pageViewEvent(pages.PROVIDER_PROFILE));
    }
  }, [error, provider, loading]);

  if (error) {
    return <Error config={config} history={history} />;
  }

  let profileContent;
  if (loading === false && provider != null) {
    const aboutPanel = provider.details.about ? (
      <About
        {...provider.details.about}
        providerName={provider.profile.name.full_name}
        degrees={provider.profile.degrees}
        log={log}
      />
    ) : null;

    const experiencePanel = provider.details.experience ? (
      <Experience {...provider.details.experience} log={log} />
    ) : null;

    const reviewsPanel = provider.details.reviews ? (
      <Reviews {...provider.details.reviews} log={log} config={config} />
    ) : null;

    const insurancesPanel = provider.details.insurance ? (
      <Insurances
        {...provider.details.insurance}
        log={log}
        disclaimerConfig={_get(config, 'insurance_disclaimer', {})}
      />
    ) : null;

    profileContent = (
      <div
        id={_get(provider, 'profile.id')}
        className="row provider"
        itemScope={true}
        itemType="http://schema.org/Physician"
        aria-live="polite"
      >
        <div className="col-xs-12 col-sm-4">
          <div className="col-sm-12" id="left-panels">
            <Summary
              provider={provider.profile}
              config={config}
              log={log}
              currentQuery={currentQuery}
              fetchSlots={fetchSlots}
              fetchPurposesByProviderId={fetchPurposesByProviderId}
              slotsByProviderId={slotsByProviderId}
              slotsStatus={slotsStatus}
              availablePurposesByProviderId={availablePurposesByProviderId}
              location={location}
              tokens={tokens}
              device={device}
            />
            <Locations
              locationsModuleEnabled={locationsModuleEnabled(config)}
              locations={provider.profile.locations}
              currentQuery={currentQuery}
              log={log}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-8">
          <div className="col-sm-12" id="right-panels">
            {!isCCF() && (
              <Nav
                provider={provider}
                config={config}
                log={log}
                location={location}
                tokens={tokens}
              />
            )}
            {aboutPanel}
            {experiencePanel}
            {reviewsPanel}
            {insurancesPanel}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container pt-m pb-m">
      <LoadingOverlay loading={loading || !provider} />
      <ProfileMetas
        provider={_get(provider, 'profile', { specialties: [] })}
        config={config}
      />
      <div className="row">
        <SearchLinks currentQuery={currentQuery} log={log} config={config} />
      </div>
      {profileContent}
      <PoweredByLogo config={config} className="visible-print" />
    </div>
  );
}

export default Profile;
