import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _startCase from 'lodash/startCase';
import _round from 'lodash/round';
import _map from 'lodash/map';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import { getProviderImageUrl } from '@kyruus/provider-components';

import { getProviderProfilePathV8 } from 'Common/urls/provider';

import { makeInternational } from '../utils/intl-components';

const messages = defineMessages({
  hasvideo: {
    id: 'providerlist.hasvideo',
    description:
      'Label to identify that the provider has a video on their profile',
    defaultMessage: 'Video'
  },
  ariaproviderreviews: {
    id: 'aria.label.link.providerreviews',
    description:
      "Accessibility label identifying a link to the provider's reviews",
    defaultMessage: "Go to provider's {reviewCount} reviews"
  },
  arialogo: {
    id: 'aria.label.provider.profile.logo',
    description:
      "Accessibility text for a logo representing a provider's affiliation",
    defaultMessage: 'Logo for {affiliation}'
  },
  ratingscale: {
    id: 'provider.profile.ratings.scale',
    description: 'Text that specifies the rating scale',
    defaultMessage: 'out of 5'
  },
  ratingcount: {
    id: 'provider.profile.ratings.ratingcount',
    description: 'The number of ratings that the provider has',
    defaultMessage:
      '{ratingCount} {ratingCount, plural, one {rating} other {ratings}}'
  }
});

const starRatingsMessages = defineMessages({
  ariastarratingsdefault: {
    id: 'aria.label.starratings.default',
    description: "Accessibility label identifying the provider's star ratings",
    defaultMessage: 'Provider star ratings'
  },
  ariastarratingsaverage: {
    id: 'aria.label.starratings.average',
    description:
      "Accessibility label identifying the provider's average star ratings",
    defaultMessage: 'Average provider rating of {averageRating} out of 5 stars'
  },
  ariaStarRating: {
    id: 'aria.label.starratings.rating',
    description: "Accessibility label identifying the provider's star rating",
    defaultMessage: 'Provider rating {rating} out of 5 stars'
  },
  ariastarsubratings: {
    id: 'aria.label.starratings.subrating',
    description:
      "Accessibility label identifying the provider's star ratings for sub-sections",
    defaultMessage: 'Sub-rating of {rating} for metric {metric}'
  }
});

const IntlImg = makeInternational('img');
const IntlLink = makeInternational(HashLink);

function BaseProviderProfileLink({
  provider,
  hash = '',
  location,
  match,
  ...props
}) {
  let search = location.search;
  if (!search && match.params.specialty) {
    const nearParam = match.params.location
      ? `&location=${match.params.location}`
      : '';
    search = `?specialty_page=true&specialty=${match.params.specialty}${nearParam}`;
  }

  const to = `${getProviderProfilePathV8(provider)}${search}${hash}`;
  const linkProps = _omit(props, ['history', 'staticContext']); // Remove props passed in through react-router

  return (
    <IntlLink to={to} {...linkProps} /> //eslint-disable-line jsx-a11y/anchor-has-content
  );
}

const ProviderProfileLink = withRouter(BaseProviderProfileLink);

function ProviderImage({ provider, hash, isLarge }) {
  const imageClass = isLarge ? 'provider-image-lg' : 'provider-image';
  const Image = (
    <img
      alt={provider.name.full_name}
      className={imageClass}
      id={`image${provider.id}`}
      src={getProviderImageUrl(provider)}
      itemProp="image"
    />
  );

  let VideoBanner = null;
  if (!_isEmpty(provider.provider_videos)) {
    const videoHiddenClass = isLarge ? 'hidden-sm hidden-md hidden-lg' : '';
    VideoBanner = (
      <div
        data-testid="VideoBanner"
        className={`has-provider-video hidden-ie8 hidden-print ${videoHiddenClass}`}
      >
        <FormattedMessage {...messages.hasvideo} />
        <span className="icon-videocam" />
      </div>
    );
  }

  return (
    <ProviderProfileLink provider={provider} hash={hash}>
      <div className="img-wrapper-for-overlay">
        {Image}
        {VideoBanner}
      </div>
    </ProviderProfileLink>
  );
}

function Stars({
  rating,
  ariaLabelMessageDescriptor = starRatingsMessages.ariastarratingsdefault,
  ariaLabelMessageDescriptorValues
}) {
  const IntlDiv = makeInternational('div');
  const width = `${rating / 0.05}%`;
  return (
    <IntlDiv
      data-testid="Stars"
      role="figure"
      className="stars"
      intlProps={{
        'aria-label': {
          descriptor: ariaLabelMessageDescriptor,
          values: ariaLabelMessageDescriptorValues
        }
      }}
    >
      <div className="star-wrapper">
        <div className="empty" data-testid="EmptyStars">
          <span className="icon-star-empty" />
          <span className="icon-star-empty" />
          <span className="icon-star-empty" />
          <span className="icon-star-empty" />
          <span className="icon-star-empty" />
        </div>
        <div
          className="filled"
          style={{ width: width }}
          data-testid="FilledStars"
        >
          <span className="icon-star" />
          <span className="icon-star" />
          <span className="icon-star" />
          <span className="icon-star" />
          <span className="icon-star" />
        </div>
      </div>
    </IntlDiv>
  );
}

function AverageRating({ averageRating = '' }) {
  return (
    <span role="figure" data-testid="AverageRating">
      {averageRating} <FormattedMessage {...messages.ratingscale} />
    </span>
  );
}

function ReviewSummary({ provider }) {
  const reviews = provider.reviews;
  const averageRating =
    _get(reviews, 'aggregate_ratings.average_rating') &&
    _round(reviews.aggregate_ratings.average_rating, 1).toFixed(1);
  if (reviews) {
    const reviewCount = _get(reviews, 'aggregate_ratings.review_count');
    const ratingCount = _get(reviews, 'aggregate_ratings.rating_count');
    return (
      <div className="col-xs-12 mb-s">
        <span className="summary-stars">
          <Stars
            rating={averageRating}
            ariaLabelMessageDescriptor={
              starRatingsMessages.ariastarratingsaverage
            }
            ariaLabelMessageDescriptorValues={{
              averageRating,
              rating: averageRating
            }}
          />
        </span>
        <span className="fc-gray-1 fw-4 mr-xs summary-avg-rating">
          <AverageRating averageRating={averageRating} />
        </span>
        <ProviderProfileLink
          provider={provider}
          hash={'#provider-details-reviews'}
          intlProps={{
            'aria-label': {
              descriptor: messages.ariaproviderreviews,
              values: { reviewCount }
            }
          }}
        >
          (
          <FormattedMessage
            {...messages.ratingcount}
            values={{ ratingCount }}
          />
          )
        </ProviderProfileLink>
      </div>
    );
  } else {
    return null;
  }
}

function ProviderBadges({ badges, themeColors = {} }) {
  if (_isEmpty(badges)) {
    return null;
  }

  const StyledBadge = styled.span`
    white-space: normal;
    text-align: left;
  `;

  const badgeList = _map(badges, (badge, index) => {
    const badgeMessageDescriptor = {
      id: `provider.badge.${badge}`,
      defaultMessage: _startCase(badge),
      description: 'Badge text for the specified field'
    };
    const themeColor = _get(themeColors, badge, 'tertiary');
    return (
      <StyledBadge
        role="status"
        data-testid={`StyledBadge_${index}`}
        className={`label provider-badge fs-xs mr-s mt-xs mb-xs kyruus-config-${themeColor}-color`}
        key={badge}
      >
        <FormattedMessage {...badgeMessageDescriptor} />
      </StyledBadge>
    );
  });

  return <div className="col-xs-12 hidden-print">{badgeList}</div>;
}

function LogoImage({ url, affiliation, ...props }) {
  return (
    <IntlImg
      src={url}
      role="presentation"
      data-testid="LogoImage"
      intlProps={{
        alt: {
          descriptor: messages.arialogo,
          values: { affiliation: affiliation }
        }
      }}
      {...props}
    />
  );
}

export {
  ProviderImage,
  ProviderProfileLink,
  Stars,
  AverageRating,
  ReviewSummary,
  ProviderBadges,
  LogoImage,
  starRatingsMessages
};
