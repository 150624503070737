import React from 'react';

import ErrorMessage from './error';
import SearchWidgetPage from '../shared/search-widget-page';

function Error(props) {
  return (
    <SearchWidgetPage {...props} >
      <ErrorMessage />
    </SearchWidgetPage>
  );
}

export default Error;
