import React from 'react';
import { injectIntl, defineMessages, FormattedMessage } from '@kyruus/intl';

import { panelTitleMessages } from './nav';
import { ExpandingDetailList } from './utils';

const messages = defineMessages({
  insurancedisclaimermessage: {
    id: 'provider.profile.insurancedisclaimer.message',
    description:
      "The message for the provider's medical insurance disclaimer message",
    defaultMessage:
      'Please contact the practice to find out if your health plan is accepted.'
  }
});

const emptyMessages = {
  insurancedisclaimertitle: {
    id: 'provider.profile.insurancedisclaimer.title',
    description:
      "The header title for the provider's medical insurance disclaimer message",
    defaultMessage: ''
  }
};

function insuranceItem(insurance) {
  const key = `${insurance.plan}-${insurance.product}`;
  const product = insurance.product ? (
    <span
      className="insurance-product fc-gray-2"
      data-testid={`InsuranceProduct-${key}`}
    >
      {insurance.product}
    </span>
  ) : null;
  return (
    <li
      className="insurance-plan mb-xs"
      data-testid={`InsurancePlan-${key}`}
      key={key}
    >
      {insurance.plan} {product}
    </li>
  );
}

function insuranceList(insurances) {
  return (
    <ul className="list-m two-col-horizontal no-bullets mb-s">{insurances}</ul>
  );
}

function DisclaimerTitleComponent({ intl }) {
  if (intl.messages['provider.profile.insurancedisclaimer.title']) {
    return (
      <span>
        <strong>
          <FormattedMessage {...emptyMessages.insurancedisclaimertitle} />{' '}
        </strong>
      </span>
    );
  } else {
    return null;
  }
}
const DisclaimerTitle = injectIntl(DisclaimerTitleComponent);

function Insurances({ data, log, disclaimerConfig }) {
  const disclaimerClass = `alert disclaimer kyruus-config-disclaimer pt-m mb-m mt-xs${
    disclaimerConfig.enabled ? '' : ' visible-print'
  }`;
  return (
    <div
      id="provider-details-insurance"
      className="row panel pt-m pb-s"
      role="region"
      aria-labelledby="InsuranceHeading"
    >
      <div className="col-xs-12">
        <div className="row mb-l">
          <div className="col-xs-12">
            <h1 id="InsuranceHeading" className="fw-6 fs-l">
              <FormattedMessage {...panelTitleMessages.insurance} />
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className={disclaimerClass} id="insurance-disclaimer">
              <p className="fs-m pl-m pb-m mr-m text-left">
                <span className="icon-info-circle pr-s fs-l" />
                <DisclaimerTitle />
                <FormattedMessage {...messages.insurancedisclaimermessage} />
              </p>
            </div>
            <div className="hidden-print">
              <ExpandingDetailList
                data={data}
                detailRow={insuranceItem}
                detailContainer={insuranceList}
                stepSize={10}
                log={log}
                detailName="insurances"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insurances;
