import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from '@kyruus/intl';
import _ from 'lodash';
import querystring from 'querystring';

import { makeInternational } from '../utils/intl-components';
import getPmcNumberForLocation from '../utils/getPmcNumberForLocation';
import { Map, GoogleMapLink } from '../provider/google-maps';
import { sortedLocations_V8 } from '../provider/utils';
import { ProviderBadges } from '../provider/provider-summary';
import { isMarketableLocation, buildLocationURL } from '../utils/location';

const messages = defineMessages({
  providershours: {
    id: 'provider.profile.providershours',
    description:
      "Button text indicating a toggle to show the provider's office hours",
    defaultMessage: "Provider's Hours"
  },
  ariacalllocation: {
    id: 'aria.label.calllocation',
    description: "Accessibility label for a link to call a provider's office",
    defaultMessage: 'Call this location'
  },
  locations: {
    id: 'provider.profile.header.locations',
    description: "Header for a section containing the provider's locations",
    defaultMessage: 'Locations'
  },
  officeHoursAriaLabel: {
    id: 'provider.profile.locations.officeHoursAriaLabel',
    description: 'Aria label for the office hours list',
    defaultMessage: 'Office Hours'
  }
});

const IntlAnchor = makeInternational('a');

function LocationBadges({ location }) {
  const badgeColors = {
    accepting_new_patients: 'tertiary',
    extended_office_hours_available: 'secondary'
  };
  const badges = _.filter(_.keys(badgeColors), (field) => {
    return location[field];
  });
  return (
    <div className="row">
      <ProviderBadges badges={badges} themeColors={badgeColors} />
    </div>
  );
}

function Phone({ location, log }) {
  const pmcPhone = getPmcNumberForLocation('phone', location);
  if (pmcPhone) {
    const logClick = () => {
      log('user_action.provider_detail.location_phone_link_clicked', {
        phone_number: pmcPhone
      });
    };

    return (
      <div className="row mt-s mb-xs">
        <div className="col-xs-1 col-sm-1">
          <span className="icon-phone fs-s fc-gray-2" />
        </div>
        <div className="col-xs-10 col-sm-10">
          <IntlAnchor
            href={`tel:${pmcPhone}`}
            className="location-phone-link"
            intlProps={{
              'aria-label': { descriptor: messages.ariacalllocation }
            }}
            onClick={logClick}
          >
            {pmcPhone}
          </IntlAnchor>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function Fax({ location }) {
  const faxNumber = getPmcNumberForLocation('fax', location);
  if (faxNumber) {
    return (
      <div className="row mb-xs">
        <div className="col-xs-1 col-sm-1">
          <span className="icon-print fs-s fc-gray-2" />
        </div>
        <div className="col-xs-10 col-sm-10">{faxNumber}</div>
      </div>
    );
  }
  return null;
}

class OfficeHours extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowHours = this.toggleShowHours.bind(this);
    this.state = { showHours: false };
  }

  toggleShowHours() {
    this.setState({ showHours: !this.state.showHours });
  }

  render() {
    if (_.isEmpty(this.props.office_hours_list)) {
      return null;
    } else {
      const officeHoursContent = (
        <ul
          className={`no-bullets list-m mt-xs${
            this.state.showHours ? '' : ' visible-print'
          }`}
          aria-label={this.props.intl.formatMessage(
            messages.officeHoursAriaLabel
          )}
        >
          {_.map(this.props.office_hours_list, (hours, i) => (
            <li className="office-hour" key={i}>
              {hours.label}
            </li>
          ))}
        </ul>
      );
      return (
        <div className="row mb-xs">
          <div className="col-xs-1 col-sm-1">
            <span className="icon-schedule fs-s fc-gray-2" />
          </div>
          <div className="col-xs-10 col-sm-10">
            <button
              className="button btn-anchor dark-link toggle-hours"
              onClick={this.toggleShowHours}
            >
              <FormattedMessage {...messages.providershours} />{' '}
              <span
                className={`fs-s icon-arrow-${
                  this.state.showHours ? 'up' : 'down'
                }`}
              />
            </button>
            {officeHoursContent}
          </div>
        </div>
      );
    }
  }
}

function Location({
  locationsModuleEnabled,
  location,
  index,
  currentQuery,
  log,
  intl
}) {
  const logLocation = (event_name, event_data) =>
    log(event_name, _.extend({ location_name: location.name }, event_data));
  const streetAddress = _.chain(['street1', 'street2', 'suite'])
    .map((field) => {
      return location[field];
    })
    .compact()
    .join(', ')
    .value();

  const currentQueryString = _.isEmpty(currentQuery)
    ? ''
    : `?${querystring.stringify(currentQuery)}`;

  const locationName =
    locationsModuleEnabled && isMarketableLocation(location) ? (
      <a
        href={buildLocationURL(
          locationsModuleEnabled,
          location,
          currentQueryString
        )}
      >
        {location.name}
      </a>
    ) : (
      location.name
    );

  return (
    <div
      className="row mt-m mb-m horizontal-rule location"
      itemProp="address"
      itemScope={true}
      itemType="http://schema.org/PostalAddress"
      role="listitem"
      aria-labelledby={`location-title-${index}`}
    >
      <div className="col-xs-1 col-sm-1 hidden-print" aria-hidden="true">
        <span className="label fs-xs bc-negative">{index}</span>
      </div>
      <div className="col-xs-10 col-sm-10">
        <div className="row mb-xs">
          <div className="col-xs-12">
            <h2
              id={`location-title-${index}`}
              className="fw-6 fs-s location-title"
            >
              {locationName}
            </h2>
          </div>
        </div>
        <div className="row mb-xs">
          <div className="col-xs-12">
            <span itemProp="streetAddress">
              <span>
                {streetAddress}
                <br />
              </span>
            </span>
          </div>
        </div>
        <div className="row mb-s">
          <div className="col-xs-12">
            <span>
              <span itemProp="addressLocality">{location.city}</span>
              {`, `}
              <span itemProp="addressRegion">{location.state}</span>
              {` `}
              <span itemProp="postalCode">{location.zip}</span>
            </span>
            <meta itemProp="addressCountry" content="US" />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 fw-6">
            <GoogleMapLink
              location={location}
              index={index}
              searchedLocation={currentQuery.location}
              log={log}
              alwaysDirections={true}
            />
          </div>
        </div>
        <Phone location={location} log={logLocation} />
        <Fax location={location} />
        <OfficeHours
          office_hours_list={location.office_hours_list}
          log={logLocation}
          intl={intl}
        />
        <LocationBadges location={location} />
      </div>
    </div>
  );
}

function Locations({
  locationsModuleEnabled,
  locations,
  currentQuery,
  log,
  intl
}) {
  if (!locations || !locations.length) {
    return null;
  }

  locations = sortedLocations_V8(locations);

  const locationComponents = _.map(locations, (location, index) => {
    return (
      <Location
        locationsModuleEnabled={locationsModuleEnabled}
        location={location}
        index={index + 1}
        currentQuery={currentQuery}
        log={log}
        key={location.external_id}
        intl={intl}
      />
    );
  });

  return (
    <div
      id="provider-details-locations"
      className="row panel mb-m"
      role="region"
      aria-labelledby="provider-details-locations-title"
    >
      <h1 id="provider-details-locations-title" className="sr-only">
        <FormattedMessage {...messages.locations} />
      </h1>
      <div className="col-xs-12">
        <div className="row">
          <Map locations={locations} />
        </div>
        <div className="row">
          <div className="col-xs-12" role="list">
            {locations[0].phone && (
              <meta itemProp="telephone" content={locations[0].phone} />
            )}
            {locations[0].fax && (
              <meta itemProp="faxNumber" content={locations[0].fax} />
            )}
            {locationComponents}
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(Locations);
