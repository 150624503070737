/**
 * Finds and returns a phone or fax number from location.contacts, searching for types 'PMC Phone'/'PMC Fax.'
 *
 * @param type {String} - The type of number we want to return ('phone' or 'fax').
 * @param location {Object} - A provider's location object.
 * @returns Either the phone/fax number for a given location, found at location.contacts[i], or location.phone/fax, or null if neither exists
 * 
 * See https://kyruus.jira.com/browse/KENG-8522 for details
 */
 
function getPmcNumberForLocation (type, location) {
  if (!location[type] && !location.contacts) {
    return null;
  }
  if (location.contacts && location.contacts.length) {
    for (const c of location.contacts) {
      if (
        (type === 'phone' && c.type === 'PMC Phone') ||
        (type === 'fax' && c.type === 'PMC Fax')) {
        return c.value;
      }
    }
  }
  return location[type] || null;
}

export default getPmcNumberForLocation;
