import React from 'react';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import _filter from 'lodash/filter';
import _has from 'lodash/has';

import { makeInternational } from '../utils/intl-components';
import { CallToAction } from '../provider/scheduling-options';

const panelTitleMessages = defineMessages({
  about: {
    id: 'provider.profile.panel.about',
    description: "Title for a panel showing the provider's 'About' section.",
    defaultMessage: 'About'
  },
  experience: {
    id: 'provider.profile.panel.experience',
    description:
      "Title for a panel showing the provider's 'Experience' section.",
    defaultMessage: 'Experience'
  },
  reviews: {
    id: 'provider.profile.panel.reviews',
    description: "Title for a panel showing the provider's 'Reviews' section.",
    defaultMessage: 'Reviews'
  },
  insurance: {
    id: 'provider.profile.panel.insurance',
    description:
      "Title for a panel showing the provider's 'Insurance' section.",
    defaultMessage: 'Insurance'
  }
});

const messages = defineMessages({
  ariaviewdetails: {
    id: 'aria.label.provider.profile.viewdetails',
    description:
      'Accessibility label for a link to view details about a provider',
    defaultMessage: "View details about this provider's {panel}"
  }
});

const PANEL_ORDER = ['about', 'experience', 'reviews', 'insurance'];

function Nav({ provider, config, log, location, tokens }) {
  const IntlAnchor = makeInternational('a');

  const filteredPanels = _filter(PANEL_ORDER, (panel) => {
    return _has(provider.details, panel);
  });

  const navItems = (filteredPanels || []).map((panel) => {
    // This is a hack to always make the first tab active. If we ever decide to make the nav sticky
    // we should maintain active state
    const isActive = panel == filteredPanels[0] ? 'active' : '';
    const panelName = <FormattedMessage {...panelTitleMessages[panel]} />;
    return (
      <li className={isActive} key={panel}>
        <IntlAnchor
          id={`detail-nav-${panel}`}
          href={`#provider-details-${panel}`}
          intlProps={{
            'aria-label': {
              descriptor: messages.ariaviewdetails,
              values: { panel }
            }
          }}
        >
          {panelName}
        </IntlAnchor>
      </li>
    );
  });

  return (
    <div
      id="provider-details-nav"
      data-testid="ProviderDetailsNavigation"
      className="row panel mb-m hidden-print"
      role="navigation"
    >
      <div className="col-xs-12 col-sm-11">
        <ul className="nav">{navItems}</ul>
      </div>
      <div className="col-sm-1 hidden-xs mt-xs">
        <CallToAction
          provider={provider.profile}
          config={config}
          log={log}
          buttonLocation="in_navbar"
          buttonAlign="right"
          location={location}
          tokens={tokens}
        />
      </div>
    </div>
  );
}

export default Nav;
export { panelTitleMessages };
