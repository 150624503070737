/**
 * Scroll to the given hash if it exists
 * Only works in browser
 * @param {string?} hash .. for example '#reviews'
 */
export function scrollToHashIfExists(hash) {
  if (hash && typeof window !== 'undefined') {
    const el = window.document.querySelector(hash);
    if (el) {
      el.scrollIntoView();
    }
  }
}
