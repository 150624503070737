import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import { PROFILE_V9_MAX_WIDTH } from './lib/constants';

export const ProfileWrapper = styled.div`
  background: ${(props) =>
    fromTheme('provider_profile_color_background')(props)};
  display: grid;
  justify-content: center;
  padding-bottom: ${fromTheme('spacing_medium')};

  #powered-by-kyruus-logo-wrapper {
    padding: ${fromTheme('spacing_medium')} 0;
  }
`;

export const ProfileContainer = styled.div`
  padding: 0 ${fromTheme('spacing_large')} ${fromTheme('spacing_medium')}
    ${fromTheme('spacing_large')};
  margin-bottom: -20px;
  max-width: ${PROFILE_V9_MAX_WIDTH};
  // when provider content is too short, we want the provider profile container to
  // consume the full width possible
  width: 100vw;

  .filter-match-checkmark-icon {
    left: 4px;
    top: 5px;
  }
`;

export const SocialWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 12px;

  button {
    border-bottom: ${fromTheme('border_width')} ${fromTheme('border_style')}
      transparent;

    &:hover:not(span > button) {
      border-bottom: ${fromTheme('border_width')} ${fromTheme('border_style')};
    }
  }
`;

export const PrintOptions = styled.div`
  position: relative;
  padding-top: 1px;

  svg {
    position: relative;
    top: 1px;
  }
`;

export const SearchLinksContainer = styled.nav`
  padding: ${fromTheme('spacing_medium')} 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrapper = styled('button')`
  display: grid;
  grid-template-columns: ${(props) =>
    props.iconPosition === 'left' ? 'repeat(2, min(25px) auto)' : 'auto auto'};
  color: ${fromTheme('color_link')};
  background: none;
  border: none;

  > span:first-child {
    padding: 0 ${fromTheme('spacing_xsmall')} 0 0;
    text-indent: initial;
    order: 2;
    cursor: pointer;
    line-height: ${fromTheme('font_line_height')};
    display: ${(props) => (props.iconPosition === 'left' ? 'block' : 'none')};

    @media (min-width: ${fromTheme('screen_small')}) {
      display: block;
    }
  }

  span {
    order: ${(props) => (props.iconPosition === 'left' ? 1 : 2)};
    display: inline-flex;
    > button,
    > a {
      height: auto;
      padding: 0 ${fromTheme('spacing_xsmall')} 0 0;
      width: ${(props) => (props.iconPosition === 'left' ? '90px' : 'auto')};
      position: absolute;
      z-index: 1;
      text-align: left;
    }
  }
`;
