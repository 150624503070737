import React from 'react';
import _get from 'lodash/get';
import _round from 'lodash/round';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import moment from 'moment';
import styled from '@emotion/styled';
import { defineMessages, injectIntl, FormattedMessage } from '@kyruus/intl';
import { DEFAULTS } from '@kyruus/provider-components';
import { fromTheme } from '@kyruus/ui-theme';

import { ExpandingDetailList } from './utils';
import {
  Stars,
  AverageRating,
  starRatingsMessages
} from '../provider/provider-summary';

const messages = defineMessages({
  ratingsdisclaimer: {
    id: 'provider.profile.ratingsdisclaimer',
    description: "Disclaimer text for where the provider's reviews originate",
    defaultMessage:
      'The Patient Satisfaction Rating is an average of all responses to the care provider related questions shown below from our survey. Patients that are treated in outpatient or hospital environments may receive different surveys, and the volume of responses will vary by question.'
  },
  noratings: {
    id: 'provider.profile.ratings.noratings',
    description:
      "Text describing that the provider doesn't have any star ratings",
    defaultMessage: "Sorry, this provider doesn't have any ratings."
  },
  ratingreviewcount: {
    id: 'provider.profile.ratings.ratingreviewcount',
    description: 'The number of ratings and reviews that the provider has',
    defaultMessage:
      '{ratings} {ratingCount, plural, one {rating} other {ratings}}, {reviews} {reviewCount, plural, one {review} other {reviews}}'
  },
  ratingsandreviews: {
    id: 'provider.profile.panel.ratingsandreviews',
    description: "Title for a panel showing the provider's 'Reviews' section.",
    defaultMessage: 'Ratings & Reviews'
  },
  reviewNoRating: {
    id: 'provider.profile.ratings.rating.norating',
    description:
      'The text to show next to the review date for a single review that does not have a rating',
    defaultMessage: 'No Rating'
  },
  reviewsAriaLabel: {
    id: 'provider.profile.reviews.ariaLabel',
    description: 'Accessibility label for the reviews section',
    defaultMessage: 'Reviews'
  },
  ratingsAriaLabel: {
    id: 'provider.profile.ratings.ariaLabel',
    description: 'Accessibility label for the ratings section',
    defaultMessage: 'Ratings'
  }
});

function RatingsDisclaimer({ hasReviews }) {
  let disclaimerClass, disclaimerMessage;
  if (hasReviews) {
    disclaimerClass = 'review-disclaimer-text';
    disclaimerMessage = messages.ratingsdisclaimer;
  } else {
    disclaimerClass = 'no-ratings-text mb-s';
    disclaimerMessage = messages.noratings;
  }

  return (
    <p data-testid="RatingsDisclaimer" className={`pt-s ${disclaimerClass}`}>
      <FormattedMessage {...disclaimerMessage} />
    </p>
  );
}

const StyledAverageRating = styled.div`
  float: left;
  font-weight: ${fromTheme('font_weight')};
  font-size: ${fromTheme('font_size_heading_4')};
  padding-left: 0px;
  margin-right: ${fromTheme('spacing_medium')};
  margin-top: ${fromTheme('spacing_xsmall')};
  text-align: right;
  display: inline-block;
`;

const StyledStarsWithReviewCount = styled.div`
  display: inline-block;
`;

const StarWrapper = styled.span`
  margin-right: 10px;
`;

// color is hardcoded as the rest of the colors on this page.
// @todo: refactor to use a theme var when redoing the profile page in 2022
const NoRating = styled.span`
  color: #667382;
  margin-right: 10px;
`;

const AggregateRating = ({ averageRating, ratingCount, reviewCount }) => {
  return (
    <div className="row ratings-title">
      <StyledAverageRating itemProp="ratingValue">
        <AverageRating averageRating={averageRating} />
      </StyledAverageRating>
      <StyledStarsWithReviewCount>
        <meta itemProp="worstRating" content="1" />
        <meta itemProp="bestRating" content="5" />
        <Stars
          rating={averageRating}
          ariaLabelMessageDescriptor={
            starRatingsMessages.ariastarratingsaverage
          }
          ariaLabelMessageDescriptorValues={{
            averageRating,
            rating: averageRating
          }}
        />
        <div className="review-count" data-testid="RatingAndReviewCount">
          <FormattedMessage
            {...messages.ratingreviewcount}
            values={{
              ratingCount,
              reviewCount,
              ratings: <span itemProp="ratingCount">{ratingCount}</span>,
              reviews: <span itemProp="reviewCount">{reviewCount}</span>
            }}
          />
        </div>
      </StyledStarsWithReviewCount>
    </div>
  );
};

function RatingsOverview({ data, intl }) {
  const averageRating =
    _get(data, 'aggregate_ratings.average_rating') &&
    _round(data.aggregate_ratings.average_rating, 1).toFixed(1);
  const ratingCount = _get(data, 'aggregate_ratings.rating_count');
  const reviewCount = _get(data, 'aggregate_ratings.review_count');

  const subRatings = _map(
    _get(data, 'aggregate_ratings.sub_ratings'),
    (subRating) => {
      const rating = _round(subRating.average_rating, 1).toFixed(1);
      const metric = subRating.metric;
      return (
        <div className="row sub-ratings" key={metric}>
          <div className="col-sm-2 col-xs-4 ratings-column">{rating}</div>
          <div className="col-sm-10 col-xs-8 star-column">
            <Stars
              rating={rating}
              ariaLabelMessageDescriptor={
                starRatingsMessages.ariastarsubratings
              }
              ariaLabelMessageDescriptorValues={{ rating, metric }}
            />
            <div className="metric">{metric}</div>
          </div>
        </div>
      );
    }
  );

  return (
    <div
      role="region"
      aria-label={intl.formatMessage(messages.ratingsAriaLabel)}
      className="row ratings"
      itemProp="aggregateRating"
      itemScope={true}
      itemType="http://schema.org/AggregateRating"
    >
      <div className="col-xs-12">
        <AggregateRating
          averageRating={averageRating}
          ratingCount={ratingCount}
          reviewCount={reviewCount}
        />
        {subRatings}
      </div>
    </div>
  );
}

function ReviewsDetail(review) {
  const ratingValue =
    review.rating != null ? getStarCount(review.rating) : review.rating;
  // TODO: internationalize the date
  const reviewDate = moment(review.review_date).format('M/D/YYYY');
  const reviewAuthor = review.author_display_name || 'Anonymous';

  const reviewBody = _map(review.body, (review, index) => {
    let reviewText = null;
    if (_has(review, 'question') && _has(review, 'response')) {
      reviewText = (
        <div className="review-question-response">
          <p
            className="review-question"
            data-testid={`ReviewQuestion-${index}`}
          >
            {review.question}
          </p>
          <p
            className="review-response"
            data-testid={`ReviewResponse-${index}`}
          >
            {review.response}
          </p>
        </div>
      );
    } else {
      reviewText = (
        <p className="review-response" data-testid={`ReviewResponse-${index}`}>
          {review.response}
        </p>
      );
    }
    return (
      <div itemProp="reviewBody" key={index}>
        {reviewText}
      </div>
    );
  });

  return (
    <div
      role="listitem"
      className="review row mb-s horizontal-rule-wide"
      itemProp="review"
      itemScope={true}
      itemType="http://schema.org/Review"
      key={review.review_id}
    >
      <meta
        itemProp="author"
        itemType="http://schema.org/Person"
        content={reviewAuthor}
      />
      <div
        className="col-xs-12 mb-s"
        itemProp="reviewRating"
        itemScope={true}
        itemType="http://schema.org/Rating"
      >
        {ratingValue ? (
          <StarWrapper>
            <Stars
              rating={ratingValue}
              ariaLabelMessageDescriptor={starRatingsMessages.ariaStarRating}
              ariaLabelMessageDescriptorValues={{
                rating: ratingValue
              }}
            />
            <meta itemProp="ratingValue" content={ratingValue.toString()} />
            <meta itemProp="worstRating" content="1" />
            <meta itemProp="bestRating" content="5" />
          </StarWrapper>
        ) : (
          <NoRating>
            <FormattedMessage {...messages.reviewNoRating} />
          </NoRating>
        )}
        <span className="review-date">{reviewDate}</span>
      </div>
      <div className="col-xs-12 mb-s review-body">{reviewBody}</div>
    </div>
  );
}

function Reviews({ data, log, intl }) {
  const hasRatings = !_isEmpty(data.aggregate_ratings);
  const hasReviews = !_isEmpty(data.reviews);
  const hasReviewsOrRatings = hasReviews || hasRatings;

  const reviewList = hasReviews ? (
    <div
      className="show-more-reviews hidden-print"
      role="list"
      aria-label={intl.formatMessage(messages.reviewsAriaLabel)}
    >
      <ExpandingDetailList
        data={data.reviews}
        detailRow={ReviewsDetail}
        stepSize={5}
        log={log}
        detailName="reviews"
      />
    </div>
  ) : null;

  const reviewContent = hasReviewsOrRatings ? (
    <div>
      <RatingsOverview data={data} intl={intl} />
      {reviewList}
    </div>
  ) : null;

  return (
    <div
      id="provider-details-reviews"
      data-testid="profile-reviews"
      className="row panel mb-m pt-m pb-s"
      role="region"
      aria-labelledby="RatingsAndReviewsHeading"
    >
      <div className="col-xs-12">
        <div className="row mb-l" id="reviews-header">
          <div className="col-xs-12">
            <h1 className="fw-6 fs-l" id="RatingsAndReviewsHeading">
              <FormattedMessage {...messages.ratingsandreviews} />
            </h1>
            <RatingsDisclaimer hasReviews={hasReviewsOrRatings} />
          </div>
        </div>
        {reviewContent}
      </div>
    </div>
  );
}

/**
 * Return a normalized star count based on a review rating and
 * a guess of the rating scale. Each rating data object does not include the original scale.
 *
 * e.g If the rating is 6, this will guess that the rating scale is 1-10 then convert
 * the star count to 60% of PROVIDER_RATING_SCALE
 *
 * @param {number} rating rating value
 * @returns {number}
 */
const getStarCount = (rating) => {
  const starRating = !rating ? 1 : rating;

  const ratingScale = DEFAULTS.PROVIDER_RATING_SCALE;
  const scaleEnd = Math.ceil(starRating / ratingScale) * ratingScale;
  const percentOfScale = starRating / scaleEnd;
  return Math.round(ratingScale * percentOfScale);
};

const IntlReviews = injectIntl(Reviews);
const IntlRatingsOverview = injectIntl(RatingsOverview);
const IntlRatingsDisclaimer = injectIntl(RatingsDisclaimer);
const IntlReviewsDetail = injectIntl(ReviewsDetail);

export {
  IntlRatingsDisclaimer as RatingsDisclaimer,
  IntlRatingsOverview as RatingsOverview,
  IntlReviewsDetail as ReviewsDetail,
  getStarCount
};
export default IntlReviews;
