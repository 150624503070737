import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  return_to_results: {
    id: 'provider.profile.returntoresults',
    description: 'Link to return to search results',
    defaultMessage: 'Back to search'
  },
  new_search: {
    id: 'provider.profile.startanewsearch',
    description: 'Link to start a new search',
    defaultMessage: 'New search'
  },
  or: {
    id: 'provider.profile.navigation.or',
    description: 'Junction of multiple options',
    defaultMessage: 'or'
  },
  doctorname: {
    id: 'provider.profile.description.doctorname',
    description: "A page description for the doctor's name and title",
    defaultMessage: 'Dr. {providerName}'
  },
  basicproviderstatment: {
    id: 'provider.profile.description.statement',
    description: "Page description for the provider's profile",
    defaultMessage:
      'Find information about and book an appointment with {providerName}.'
  },
  basicproviderstatmentlocation: {
    id: 'provider.profile.description.statementlocation',
    description: "Page description for the provider's profile with locations",
    defaultMessage:
      'Find information about and book an appointment with {providerName} in {displayLocations}.'
  },
  descriptionlocations: {
    id: 'provider.profile.description.locations',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Practice Locations: {displayLocations}.'
  },
  descriptionspecialties: {
    id: 'provider.profile.description.specialties',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Specialties: {specialties}.'
  },
  moreAvailabilitiesLinkText: {
    id: 'availability.moreavailabilities',
    description: 'Text to display on link to view more availabilities',
    defaultMessage: 'View All Appointments'
  },
  providerAvailabilitySlotsAriaLabel: {
    id: 'availability.slotsAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider available slots',
    defaultMessage: '{date} and {time} at {locationName}'
  },
  providerAvailabilityViewMoreAriaLabel: {
    id: 'availability.viewMoreAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider view more slots',
    defaultMessage: 'view all available appointments for {providerName}'
  },
  providerProfileEditApptInfoAriaLabel: {
    id: 'availability.editApptInfoAriaLabel',
    description:
      'Accessibility text to be read by screen reader for edit appointment info',
    defaultMessage: 'Edit your appointment information'
  },
  existingPatientButtonText: {
    id: 'patientrel.existing.buttontext',
    description:
      'Button text for "existing" patientRel button when querying availability for a single provider.',
    defaultMessage: 'Yes'
  },
  newPatientButtonText: {
    id: 'patientrel.new.buttontext',
    description:
      'Button text for "new" patientRel button when querying availability for a single provider.',
    defaultMessage: 'No'
  }
});
