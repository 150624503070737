import React from 'react';
import _ from 'lodash';
import { defineMessages, FormattedMessage } from '@kyruus/intl';

import { makeInternational } from '../utils/intl-components';
import SecureNewTabLink from '../utils/secure-new-tab-link';

const messages = defineMessages({
  getdirections: {
    id: 'providerlist.getdirections',
    description:
      "Label for a link that links to directions to the provider's office",
    defaultMessage: 'Get Directions'
  },
  directionslink: {
    id: 'providerlist.directionslink',
    description:
      "Label for a link that links to directions to the provider's office",
    defaultMessage: 'Directions'
  },
  maplink: {
    id: 'providerlist.maplink',
    description:
      "Label for a link that links to map showing the location of the provider's office",
    defaultMessage: 'Map'
  },
  maplinklabel: {
    id: 'aria.label.maplink',
    description:
      "Accessibility label for a link to a map of a provider's office",
    defaultMessage: 'Go to a map of {location}'
  },
  ariamapcontainer: {
    id: 'aria.label.mapcontainer',
    description:
      "Accessibility label identifying the map of the provider's offices",
    defaultMessage: "Map of the provider's office(s)"
  }
});

const SecureIntlAnchor = makeInternational(SecureNewTabLink);

function GoogleMapLink({ location, searchedLocation, alwaysDirections, log }) {
  const baseMapUrl = 'https://www.google.com/maps';
  const locationFields = [
    'street1',
    'street2',
    'suite',
    'city',
    'state',
    'zip'
  ];
  let locationString = _.chain(locationFields)
    .map((field) => {
      return location[field];
    })
    .compact()
    .value()
    .join(' ');
  let locationURI = encodeURIComponent(locationString);
  var mapHref;
  if (searchedLocation || alwaysDirections) {
    // Note: URL format is `${baseMapUrl}/dir/${start address}/${destination address}`
    // if no searchLocation, empty string as placeholder ("...//...") for start address
    mapHref = `${baseMapUrl}/dir/${encodeURIComponent(
      searchedLocation || ''
    )}/${locationURI}/`;
  } else {
    mapHref = `${baseMapUrl}/place/${locationURI}/`;
  }

  let linkTextMessageDescriptor;
  if (alwaysDirections) {
    linkTextMessageDescriptor = messages.getdirections;
  } else if (searchedLocation) {
    linkTextMessageDescriptor = messages.directionslink;
  } else {
    linkTextMessageDescriptor = messages.maplink;
  }
  return (
    <SecureIntlAnchor
      className="map-link hidden-print"
      href={mapHref}
      onClick={() =>
        log('user_action.search_results.map_link_clicked', {
          location_name: location.name,
          map_type: linkTextMessageDescriptor.defaultMessage
        })
      }
      intlProps={{
        'aria-label': {
          descriptor: messages.maplinklabel,
          values: { location: locationString }
        }
      }}
    >
      <FormattedMessage {...linkTextMessageDescriptor} />
    </SecureIntlAnchor>
  );
}

class Map extends React.Component {
  constructor() {
    super();
    this.renderMap = this.renderMap.bind(this);
  }

  renderMap(mapRef) {
    if (mapRef && typeof window.google == 'object' && window.google.maps) {
      const MAX_MAP_ZOOM = 16;

      let map = new window.google.maps.Map(mapRef, {
        maxZoom: MAX_MAP_ZOOM
      });

      let bounds = new window.google.maps.LatLngBounds();
      _.forEach(this.props.locations, ({ name, coordinates } = {}, index) => {
        const { lat, lon } = coordinates || {};

        // If a location is not renderable on the map, skip creating a map marker for it
        if (lat != null && lon != null) {
          let marker = new window.google.maps.Marker({
            position: {
              lat: lat,
              lng: lon
            },
            title: name,
            label: (index + 1).toString(),
            map: map
          });
          bounds.extend(marker.getPosition());
        }
      });
      map.fitBounds(bounds);
    }
  }

  render() {
    const locationsWithInvalidCoordinates = this.props.locations.reduce(
      (accum, loc) =>
        loc.coordinates?.lat == null || loc.coordinates?.lon == null
          ? [...accum, loc]
          : accum,
      []
    );
    const hasNoValidLocations =
      locationsWithInvalidCoordinates.length > 0 &&
      locationsWithInvalidCoordinates.length === this.props.locations.length;
    const intlDivProps = hasNoValidLocations
      ? {
          className: 'hidden-print no-display',
          componentRef: null,
          'data-nomap':
            'Map not displayed due to all locations having missing or invalid location coordinates'
        }
      : {
          className: 'hidden-print',
          componentRef: this.renderMap,
          ...(locationsWithInvalidCoordinates.length > 0
            ? {
                'data-nomarker': `No map marker displayed for the following locations due to missing or invalid location coordinates: ${locationsWithInvalidCoordinates
                  .map((loc) => loc.name)
                  .join(', ')}`
              }
            : {})
        };
    const IntlDiv = makeInternational('div');
    // Because this a third-party integration that requires an actual DOM node, we need to use refs
    return (
      <IntlDiv
        id="map-canvas"
        role="application"
        intlProps={{ 'aria-label': { descriptor: messages.ariamapcontainer } }}
        {...intlDivProps}
      />
    );
  }
}

export { Map, GoogleMapLink };
