import React from 'react';
import { defineMessages, injectIntl } from '@kyruus/intl';
import SocialShare from '@kyruus/social-share';

import { specialtyNames_V8 } from '../provider/utils';
import { pageTitle, formattedProviderName } from './profile-metas';
import { makeInternational } from '../utils/intl-components';

import { getProviderProfileUrlV8 } from 'Common/urls/provider';

export const messages = defineMessages({
  emaillabel: {
    id: 'socialshare.email.button',
    description: 'Email label for the email button',
    defaultMessage: 'Email'
  },
  printlabel: {
    id: 'socialshare.print.button',
    description: 'Print label for the share button',
    defaultMessage: 'Print'
  },
  sharelabel: {
    id: 'socialshare.share.label',
    description: 'share label for the share bar',
    defaultMessage: 'Share{colon}'
  },
  facebookLabel: {
    id: 'socialshare.share.facebook',
    description: 'facebook label to redirect facebook page',
    defaultMessage: 'Facebook'
  },
  twitterXLabel: {
    id: 'socialshare.share.twitter',
    description: 'twitter label to redirect facebook page',
    defaultMessage: 'X'
  },
  shareDropDownAriaLabel: {
    id: 'socialshare.share.shareDropDownAriaLabel',
    description: 'social share drp down menu arial label text',
    defaultMessage: 'social share dropdown'
  },
  printProviderProfileAriaLabel: {
    id: 'socialshare.print.providerProfileAriaLabel',
    description: 'print provider profile arial label text',
    defaultMessage: 'print provider profile'
  },
  profileCopiedLinkSnackbarMessage: {
    id: 'provider.profile.snackbarMessageCopiedUrl',
    description:
      'Snackbar message to display when the profile link url is copied',
    defaultMessage: 'Profile link copied'
  }
});

const messageProps = {
  emailLabel: { descriptor: messages.emaillabel },
  printLabel: { descriptor: messages.printlabel },
  shareLabel: {
    descriptor: messages.sharelabel,
    values: {
      colon: ':'
    }
  }
};

const IntlSocialShare = makeInternational(SocialShare);

const logShare = (logEvent = {}, log) => {
  if (logEvent.eventType === 'print') {
    log('user_action.provider_detail.print');
  } else {
    log(`user_action.provider_detail.share.${logEvent.eventType}`);
  }
};

function ProviderProfileShare({ provider, config, log, device, intl }) {
  const socialShareConfig = config['social_share'] || {};

  const facebook = !!socialShareConfig.facebook;
  const twitter = !!socialShareConfig.twitter || !!socialShareConfig.twitterX;
  const email = !!socialShareConfig.email;

  const url = getProviderProfileUrlV8(
    config.primary_domain || config.host,
    provider
  );
  const providerName = formattedProviderName({ provider, intl });
  const specialties = specialtyNames_V8(provider).join(', ');
  const title = pageTitle({ providerName, specialties, intl });
  const showPrint = !device.isWebview; /** disable print on webview */

  return (
    <IntlSocialShare
      url={url}
      text={title}
      showPrint={showPrint}
      showEmail={email}
      showFacebook={facebook}
      showTwitterX={twitter}
      onBeforeClick={(e) => logShare(e, log)}
      intl={intl}
      intlProps={messageProps}
    />
  );
}

export default injectIntl(ProviderProfileShare);
