import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { defineMessages, injectIntl } from '@kyruus/intl';
import { getProviderImageUrl } from '@kyruus/provider-components';

import {
  getProviderDisplayName_V8,
  isDr,
  specialtyNames_V8,
  sortedLocations_V8,
  showCTA_V8
} from '../provider/utils';
import { messages as schedulingOptionMessages } from '../provider/scheduling-options';

import {
  getProviderProfilePathV8,
  getProviderProfileUrlV8
} from 'Common/urls/provider';

const messages = defineMessages({
  doctorname: {
    id: 'provider.profile.description.doctorname',
    description: "A page description for the doctor's name and title",
    defaultMessage: 'Dr. {providerName}'
  },
  basicproviderstatment: {
    id: 'provider.profile.description.statement',
    description: "Page description for the provider's profile",
    defaultMessage:
      'Find information about and book an appointment with {providerName}.'
  },
  basicproviderstatmentlocation: {
    id: 'provider.profile.description.statementlocation',
    description: "Page description for the provider's profile with locations",
    defaultMessage:
      'Find information about and book an appointment with {providerName} in {displayLocations}.'
  },
  descriptionlocations: {
    id: 'provider.profile.description.locations',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Practice Locations: {displayLocations}.'
  },
  descriptionspecialties: {
    id: 'provider.profile.description.specialties',
    description: "Page description for the doctor's locations",
    defaultMessage: 'Specialties: {specialties}.'
  }
});

export function formattedProviderName({ provider, intl }) {
  let providerName = getProviderDisplayName_V8(provider);
  if (isDr(provider)) {
    providerName = intl.formatMessage(messages.doctorname, {
      providerName
    });
  }
  return providerName;
}

export function pageTitle({
  providerName,
  locations,
  specialties,
  showProviderCta,
  intl
}) {
  const titleSpecialties = _.isEmpty(specialties) ? '' : ` - ${specialties}`;
  const closestLocation = _.isEmpty(locations) ? '' : ` - ${locations[0]}`;
  const callToAction = showProviderCta
    ? ` - ${intl.formatMessage(schedulingOptionMessages.ctabutton)}`
    : '';

  return `${providerName}${closestLocation}${titleSpecialties}${callToAction}`;
}

function pageDescription(
  providerName,
  locations,
  specialties,
  professional_statement,
  intl
) {
  const displaySpecialties = specialties
    ? intl.formatMessage(messages.descriptionspecialties, {
        specialties
      })
    : '';
  let displayLocations = _.chain(locations).uniq().join(', ').value();

  let description;
  if (professional_statement) {
    displayLocations = _.isEmpty(displayLocations)
      ? ''
      : intl.formatMessage(messages.descriptionlocations, {
          displayLocations
        });
    description = `${professional_statement} ${displayLocations}`;
  } else {
    const summaryDescriptor = _.isEmpty(displayLocations)
      ? messages.basicproviderstatment
      : messages.basicproviderstatmentlocation;
    description = intl.formatMessage(summaryDescriptor, {
      providerName,
      displayLocations
    });
  }
  return `${description} ${displaySpecialties}`;
}

function ProfileMetas({ intl, provider, config }) {
  const providerProfileUrl = getProviderProfilePathV8(provider);
  const providerName = formattedProviderName({ provider, intl });
  const locations = _.map(sortedLocations_V8(provider.locations), (loc) => {
    return `${loc.city}, ${loc.state}`;
  });
  const specialties = specialtyNames_V8(provider).join(', ');

  const host = config.primary_domain || config.host;
  const url = getProviderProfileUrlV8(host, provider);

  const showProviderCta = showCTA_V8(provider, config, true);
  const title = pageTitle({
    providerName,
    locations,
    specialties,
    showProviderCta,
    intl
  });

  const descriptionMeta = {
    name: 'description',
    content: pageDescription(
      providerName,
      locations,
      specialties,
      provider.professional_statement,
      intl
    )
  };
  const urlMeta = { name: 'url', itemprop: 'url', content: url };
  const ogTitleMeta = { property: 'og:title', content: title };
  const ogImageMeta = {
    property: 'og:image',
    content: getProviderImageUrl(provider)
  };

  const links = _.map(config.locale_domains, (localeConfig) => {
    return {
      rel: 'alternate',
      href: `${localeConfig.domain}${providerProfileUrl}`,
      hreflang: localeConfig.locale
    };
  });
  links.push({ rel: 'canonical', href: url });

  return (
    <Helmet
      title={title}
      link={links}
      meta={[descriptionMeta, urlMeta, ogTitleMeta, ogImageMeta]}
    />
  );
}

export default injectIntl(ProfileMetas);
